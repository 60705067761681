import React, { useEffect } from "react";
import { Redirect, Route } from "react-router";
import { Switch } from "react-router-dom";
import Conferences from "../containers/conferance/Conferances";
import { UseAuthDataStateValue } from "../context/AuthContext";
import { UseGlobalConfigDataState } from "../context/GlobalConfigDataContext";
import AuthManager from "../managers/AuthManager";
import ContinueCheckout from "./continue-checkout";
import Error from "./error";

const MainRoute = () => {

  const { setAuthDataState } = UseAuthDataStateValue();
  const { setGlobalConfigDataState } = UseGlobalConfigDataState();
  const queryParams = new URLSearchParams(window.location.hash.split("?")[1]);

  const getQueries = () => {
    setAuthDataState({
      type: "UPDATE_AUTH_TOKEN",
      token: queryParams.get("token") ? queryParams.get("token") : ""
    });

    if (queryParams.get("expo_id")) {
      setGlobalConfigDataState({
        type: "UPDATE_EXPO_ID",
        expo_id: queryParams.get("expo_id")
      });
    }
    if (queryParams.get("conference")) {
      setGlobalConfigDataState({
        type: "UPDATE_CONFERENCE",
        conference: queryParams.get("conference")
      });
    }
  };

  const getCSRF = async () => {
    try {
      const response = await AuthManager.getCSRFToken();

      setAuthDataState({
        type: "UPDATE_CSRF_TOKEN",
        token: response.data.data
      });
    } catch (e) {
      console.log("CSRF is not created");
    }
  };

  useEffect(() => {
    (async function useEffectIIFE() {
      getQueries();
      await getCSRF();
    })();
  }, []);

  return (
    <Switch>
      <Route path="/continue-checkout" component={ContinueCheckout} exact />
      <Route path="/conference" children={<Conferences />} />
      <Route path="/404" component={Error} />
      <Redirect from='*' to='/404' />
    </Switch>
  );
};

export default MainRoute;
